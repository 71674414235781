export default {
  updateWinWidth: ({ commit }, width) => {
    commit('SET_WIN_WIDTH', width)
  },
  updateWinHeight: ({ commit }, height) => {
    commit('SET_WIN_HEIGHT', height)
  },
  updateCurrentPath: ({commit}, path) => {
    commit('SET_CURRENT_PATH', path)
  }
}
