export default {
  SET_WIN_WIDTH: (state, width) => {
    state.winWidth = width
  },
  SET_WIN_HEIGHT: (state, height) => {
    state.winHeight = height
  },
  SET_CURRENT_PATH (state, path) {
    state.currentPath = path
  }
}
