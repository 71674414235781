const Home = () => import('../views/Home')
const Doc = () => import('../views/Doc')
const Contact = () => import('../views/Contact')
const About = () => import('../views/About')

export default [
  { path: '/', name: 'index', redirect: '/home' },
  { path: '/home', name: 'home', component: Home },
  { path: '/doc', name: 'doc', component: Doc },
  { path: '/contact', name: 'contact', component: Contact },
  { path: '/about', name: 'about', component: About },
  { path: '*', component: Home }
]
