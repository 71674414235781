<template>
  <div id="app">
    <header class="header view-padding" :class="{notransparent: !isTrans}">
      <nav class="inner">
        <router-link to="/" exact class="logo-box">
          <img class="logo" src="./assets/images/yxy-logo-green.png" alt="logo"/>
        </router-link>
        <a class="sub-title"><span class="content">复旦科技园临床生物样本研究中心</span></a>
        <router-link to="/contact" class="menu"><span class="content">联系我们</span></router-link>
        <router-link to="/about" class="menu"><span class="content">关于银杏叶</span></router-link>
        <router-link to="/doc" class="menu"><span class="content">样本库产品</span></router-link>
        <a class="menu" target="_blank" href="https://www.pubmedop.com"><span class="content">文献与基金</span></a>
        <a class="menu" target="_blank" href="http://www.iyxy.cn/workshop/index.html"><span class="content">银杏叶课堂</span></a>
        <router-link to="/home" class="menu"><span class="content">首页</span></router-link>
      </nav>
    </header>
    <router-view class="view"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  computed: {
    isTrans () {
      return (this.$store.state.currentPath === '/home' ||
              this.$store.state.currentPath === '/contact' ||
              this.$store.state.currentPath === '/about')
    }
  }
}
</script>

<style lang="stylus">
body
  font-family -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  font-size 15px
  /*background-color lighten(#eceef1, 30%)*/
  min-width 1000px
  background-color #fff
  margin 0
  /*padding-top 55px*/
  color #34495e
  overflow-y scroll

a
  color #34495e
  text-decoration none

.header
  background-color rgba(55, 81, 123, 0.6)
  /*background transparent*/
  position absolute
  z-index 999
  height 55px
  top 0
  left 0
  right 0
  .inner
    box-sizing border-box
    margin 0px auto
    height 55px
    line-height 55px
  a
    position relative
    color rgba(255, 255, 255, 1)
    transition color .15s ease
    display inline-block
    font-weight 300
    letter-spacing .075em
    margin-right 2.2em
    height 52px
    line-height 52px
    &.menu
      position relative
      float right
      .content
        top 8px
        position relative
        font-weight 400
    &:hover
      color rgb(66, 189, 240)
      .sub-menus
        display block
        transition all .2s ease-in-out
    &.router-link-active
      /*color rgb(66, 189, 240)*/
      font-weight 400
      border-bottom 3px solid rgb(95, 208, 248)
  .login
    cursor pointer
    margin 0
    float right
    .content
      top 8px
      position relative
      font-weight 400
    &:hover
      color rgba(255,255,255,0.8)
  .logo-box
    margin-right 10px
  .sub-title
    cursor default
    .content
      top 10px
      position relative
      color rgba(255, 255, 255, .8)
    &:hover
      color rgba(255,255,255,0.8)
  &.notransparent
    position relative
    &:after
      content " "
      position absolute
      bottom 0
      left 0
      right 0
      height 1px
      background #ddd
    .sub-menus
      background #fff
      border-left: 1px solid #ddd
      border-right: 1px solid #ddd
      border-bottom: 1px solid #ddd
      margin-top: 15px

.logo
  top -2px
  position relative
  height 42px
  margin-right 10px
  display inline-block
  vertical-align middle

.view
  /*max-width 800px*/
  margin 0 auto
  overflow-x hidden
  position relative

.fade-enter-active, .fade-leave-active
  transition all .2s ease

.fade-enter, .fade-leave-active
  opacity 0

@media (max-width 860px)
  .header .inner
    padding 15px 30px

@media (max-width 600px)
  .header
    .inner
      padding 15px
    a
      margin-right 1em
    .login
      display none

.view-padding
  padding 0 10%

.sub-menus
  position absolute
  z-index 5
  display none
  list-style none
  padding 0
  margin 16px 0
  left -5px
  background-color rgba(255, 255, 255, 0.6)

.sub-menu
  height 32px
  line-height 32px
  padding 0 5px
  font-weight normal
  transition all .2s ease-in-out
  color #34495e
  span
    white-space nowrap
    font-weight 300
    margin 0
  &:hover
    color #42bdf0;
    transition all .2s ease-in-out
  &:last-child
    margin 0 0 5px 0
</style>
